const initialState = {
  userAgent                 : "",
  enableLogging             : false,
  logConfigs                : "",
  minCSVersions             : "",
  serverVersion             : "",
  serverInfo                : "",
  servers                   : [],
  ssoGroups                 : [],
  unsupportedFeatureDetected: false,
  supportedFeatures         : [
    "portal",
    "saml",
    "oauth",
    "legacyAuth",
    "legacyAuthWithOTP",
    "legacyAuthWithVouching",
    "TOTP",
    "POK",
    "catalyst",
    "reverseProxy",
    "customQR",
    "widget",
    "ldap",
    "secureEnrolment",
    "deployment",
    "radius",
    "audit",
    "aucloud",
    "tli",
    "anonymous",
    "teleport",
    "helpdesk"
  ],
  enabledFeature: {
    portal                : false,
    saml                  : false,
    oauth                 : false,
    legacyAuth            : false,
    legacyAuthWithOTP     : false,
    legacyAuthWithVouching: false,
    TOTP                  : false,
    POK                   : false,
    catalyst              : false,
    reverseProxy          : false,
    customQR              : false,
    widget                : false,
    ldap                  : false,
    secureEnrolment       : false,
    deployment            : false,
    radius                : false,
    audit                 : false,
    aucloud               : false,
    tli                   : false,
    anonymous             : false,
    teleport              : false,
    helpdesk              : false,
  },
  serviceTypes: {
    SAML        : "SAML 2.0",
    ReverseProxy: "Reverse Proxy",
    Href        : "Href",
    Portal      : "Portal",
    Catalyst    : "Catalyst",
    Deployment  : "Deployment",
    Radius      : "RADIUS Adaptor",
    TLI         : "Website Integration",
    Anonymous   : "Anonymous",
    Helpdesk    : "Helpdesk"
  },
  pokUsername           : "",
  totpUsername          : "",
  legacyAuthUsername    : "",
  legacyAuthPassword    : "",
  legacyAuthRealPassword: "",
  useCompatibilityMode  : false,
  defaultQRURLScheme    : "cypher://",
  veriCode              : "",
  teleportKey           : "",
  customHomePage        : "",
  legacyInFlight        : false,
  contextSwitchShowAuth : false,
  contextSwitchEnrolDone: false,
}

const updateState = (oldState, newState) => {
  return Object.assign({}, oldState, newState);
}
const reducer = (state=initialState, action) => {
  switch(action.type){
    case 'Logout':
      return updateState(state, initialState);
    case 'UpdateReduxState':
      return updateState(state, {...action.payload});
    case 'CloseModal':
      let data = {

      }
      data = Object.assign({}, data, {...action.payload});
      return updateState(state, data);
    default:
      return {...state}
  }
}

export default reducer;

